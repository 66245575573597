import React, {useState, useEffect} from 'react';
import { Layout, Menu, Row, Col, Divider, Grid, Input, Card } from 'antd';
import './LandingPage.css'; // Optional for custom styling
import logo from './assets/logo.png';
import homeoffice from './assets/homeoffice.png';
import whatwedo from './assets/whatwedo.png';
import { SearchOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import MenuPage from './MenuPage';
import News from './News';
import OurPartners from './OurPartners';
import ConnectWithUs from './ConnectWithUs';
import focus from './assets/focus.png';

const { useBreakpoint } = Grid;

const { Header, Content } = Layout;

const Navbar = () => {
  const [bannerHeight, setBannerHeight] = useState('auto');
  const [menuState, setMenuState] = useState('content');
  const screens = useBreakpoint(); // Get the current screen size

  useEffect(() => {
    const handleResize = () => {
      // Calculate available height for banner
      const headerHeight = document.querySelector('.header').offsetHeight;
      setBannerHeight(`calc(100vh - ${headerHeight}px)`);
    };

    // Set initial height
    handleResize();
    
    // Add resize event listener
    window.addEventListener('resize', handleResize);

    // Clean up on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <Layout>
      <Header className="header"  style={{backgroundColor:'#fff', display:'flex'}}>
        <div className="logo" style={{width: '50%', display:'flex'}}>
          <img src={logo} alt='logo' style={{width:screens.md ?'30%':'80%'}}/>
        </div>
        {
          menuState === 'content' && (
            <Menu mode="horizontal"  style={{ width: '100%', display:'flex', justifyContent:'end', border:'none'}}>
              <Menu.Item key="search" icon={<SearchOutlined />} onClick={() => {
                setMenuState('search');
              }}>
                {/* Conditionally render the text based on screen size */}
                {screens.md ? 'Search Everything' : null}
              </Menu.Item>
              <Menu.Item key="menu" icon={<MenuOutlined />} onClick={() => {
                setMenuState('menu');
              }}>
                Menu
              </Menu.Item>
            </Menu>
          )
        }

        {
          (menuState === 'search' || menuState === 'menu') && (
            <Menu selectable={false} mode="horizontal" style={{ width: '100%', display:'flex', justifyContent:'end', border:'none'}}>
              <Menu.Item key="menu" icon={<CloseOutlined />} onClick={() => {
                setMenuState('content');
              }}>
                Close
              </Menu.Item>
            </Menu>
          )
        }
      </Header>
      {menuState === 'search' && (<div id='header-search' style={{ height: bannerHeight, backgroundColor:'#fff' }}>
        <Content className="header" style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', width: screens.md ? '60%' : '80%', margin:'30px auto'}}>
          <h2>What can we help you find?</h2>
          <Input
            prefix={<SearchOutlined style={{ color: '#001628', fontSize:'1.5em', margin:'0 10px' }} />} // Set icon color
            placeholder="Search"
            style={{
              borderColor: '#001628', // Change border color
              color: '#001628', // Change text color
              height: '70px', // Increase height
            }}
          />
        </Content>
      </div>)}
      { menuState === 'menu' && (
        <MenuPage height={bannerHeight} />
      )}
    
      <div id='content-page'>
        <Content className="banner" style={{ height: bannerHeight }}>
          <div className="banner-overlay" />
            <Row justify="center" align="middle" className="banner-content" style={{padding: '150px'}}>
              <Col span={12} className='banner-content'>
                <h1 className="banner-title">HEALTH PRECISION</h1>
                <p className="banner-description">
                  Established in 2022, we are pioneers in genetic-based platforms for healthcare, committed to unlocking each person's unique potential through innovation in human health.
                </p>
              </Col>
            </Row>
            <div />
        </Content>

        <Content className="general-info">
          <Row>
            <Col span={12}>
              <div className='general-info-text left' style={{backgroundColor:'#eee', color:'rgba(0, 22, 40, 1)', padding: '70px'}}>
                <h2>ABOUT US</h2>
                <p >
                Established in 2022, Health Precision Service Limited is a pioneer in bringing genetic-based platforms to the healthcare industries. We are passionate about recognising the importance of genetic innovation for human health. We believe that each person is unique, and we would like to bring the best out of them. Our culture is the lighthouse that guides the Health Precision Service into a bright future.
                </p>
              </div>
              <div className='general-info-img left' style={{backgroundColor:'#ddd', color:'#fff'}}>
                <img src={whatwedo} alt='homeoffice'/>
              </div>
              <div className='general-info-text left' style={{backgroundColor:'#eee', color:'rgba(0, 22, 40, 1)', padding: '70px'}}>
                <h2>Our Focus</h2>
                <p>
                Established in 2022, Health Precision Service Limited is a pioneer in bringing genetic-based platforms to the healthcare industries. We are passionate about recognising the importance of genetic innovation for human health. We believe that each person is unique, and we would like to bring the best out of them. Our culture is the lighthouse that guides the Health Precision Service into a bright future.
                </p>
              </div>
            </Col>
            <Col span={12}>
              <div className='general-info-img right' style={{backgroundColor:'#bbb', color:'#fff'}}>
                <img src={homeoffice} alt='process'/>
              </div>
              <div className='general-info-text right' style={{backgroundColor:'#fff', color:'rgba(0, 22, 40, 1)', padding: '70px'}}>
                <h2>What we do</h2>
                <p>
                Our process is simple and straightforward. We start by collecting your DNA sample, then we analyse it to identify your genetic markers. We then use this information to create a personalised health plan that is tailored to your unique genetic makeup. Our goal is to help you achieve optimal health and wellness by providing you with the information you need to make informed decisions about your health.
                </p>
              </div>
              <div className='general-info-img right' style={{backgroundColor:'#ddd', color:'#fff', height:'450px'}}>
                <img src={focus} alt='vision'/>
              </div>
            </Col>
          </Row>
        </Content>  

        {/* News */}
        <Content className="news" style={{backgroundColor:'#fff', padding:'30px 100px'}}>
          <News />
        </Content>

        <Content className="our-partners" style={{backgroundColor:'#fff', padding: '30px 0'}}>
          <Divider style={{backgroundColor:'#fefefe', margin:'0 100px'}}/>
          <OurPartners />
        </Content>

        <Content className="our-partners" style={{backgroundColor:'#001628'}}>
          <ConnectWithUs />
        </Content>

        <Content className="footer" style={{backgroundColor:'#fff', padding:'20px 200px'}}>
          <Row className="footer-content">
            <Col span={8} className='footer-item'>
              <img src={logo} alt='logo' style={{width:'70%'}}/>
            </Col>
            <Col span={16} className='footer-item'>
              <h2 style={{color:'#001628'}}>HEALTH PRECISION SERIVCE</h2>
              <p style={{color:'#001628'}}>Address: Room 2 – Floor 8 – Pearl Plaza, 561A Dien Bien Phu Street, Ward 25, Binh Thanh District, Ho Chi Minh City, Vietnam</p>
              <p style={{color:'#001628'}}>Phone: +84 (0) 93 407 8418</p>
              <p style={{color:'#001628'}}>Email: admin@healthprecisionservice.com</p>
            </Col>
          </Row>
        </Content>
        <Divider style={{backgroundColor:'#a3a3a3', width:'100vw !important', margin:0}}/>
        <Content className="footer" style={{backgroundColor:'#fff', padding:'20px 200px'}}>
          <Row className="footer-content">
            <Col span={24} className='footer-item' style={{alignItems:'center'}}>
              <p style={{color:'#001628'}}>©2024 by Health Precision Service LTD All Rights Reserved</p>
            </Col>
          </Row>
        </Content>
      </div>
     
    </Layout>
  );
};

export default Navbar;