import React from 'react';
import { Card, Col, Row } from 'antd';
import { UserOutlined, CalendarOutlined } from '@ant-design/icons';
import test1 from './assets/test2.png';

const newsData = [
  { title: 'News Title 1', description: 'Description for news 1', date: '2024-09-20', author: 'John Doe' },
  { title: 'News Title 2', description: 'Description for news 2', date: '2024-09-19', author: 'Jane Smith' },
];

const News = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1> News </h1>
      <Row gutter={16}>
        {newsData.map((item, index) => (
          <Col span={8} key={index}>
            <Card
              title={item.title}
              extra={<a href="#">Read More</a>}
              cover={<img alt="news" src={test1} style={{ height:'250px'}} />} // Optional: Add image if needed
            >
              <p>{item.description}</p>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.9em' }}>
                <span>
                  <UserOutlined /> {item.author}
                </span>
                <span>
                  <CalendarOutlined /> {item.date}
                </span>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default News;
