import React from 'react';
import partner1 from './assets/partner1.png';
import partner2 from './assets/partner2.png';
import partner3 from './assets/partner3.png';
import partner4 from './assets/partner4.png';


const partnerData = [
  { name: 'Partner 1', logoSrc: partner1 },
  { name: 'Partner 2', logoSrc: partner2 },
  { name: 'Partner 3', logoSrc: partner3 },
  { name: 'Partner 4', logoSrc: partner4 },
];

const OurPartners = () => {
  return (
    <div style={{ padding: '20px', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}>
      <h2>Our Partners</h2>
      <div>
        {partnerData.map((item, index) => (
          <img key={index} src={item.logoSrc} alt={item.name} style={{ width: '150px', margin: '10px' }} />
        ))}
      </div>
    </div>
  );
};

export default OurPartners;
