import React from 'react';
import { Card, Button, Row, Col } from 'antd';
import { FacebookOutlined, YoutubeOutlined } from '@ant-design/icons';
import Link from 'antd/es/typography/Link';

const socialData = [
  { name: 'Facebook', icon: <FacebookOutlined /> , link: 'https://www.facebook.com/'},
  { name: 'Youtube', icon: <YoutubeOutlined /> , link: 'https://www.youtube.com/'},
];

const ConnectWithUs = () => {
  return (
    <div style={{ padding: '40px 200px', color: '#fff', display:'flex', justifyContent:'space-between'}}>
      <h2 style={{fontSize:'24px'}}><b>Connect with Us on social</b></h2>
      <div >
        {socialData.map((item, index) => (
          <Link key={index} href={item.link} target="_blank" style={{color:'#fff'}}>
            <span style={{fontSize:'30px', padding: '0 20px'}}>{item.icon}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ConnectWithUs;
