import React, { useState, useCallback, useEffect } from 'react';
import { Table, Card, Button, Select, Input, Form } from 'antd';
import { PlusOutlined, ExportOutlined, EditOutlined,DeleteOutlined } from '@ant-design/icons';
import PatientCreateModal from './PatientCreateModal';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Search } = Input;
const { Item } = Form;

const PatientInput = (isEditPage) => {
  const [openModal, setOpenModal] = useState(false);
  const [listPatient, setListPatient] = useState([]);
  const [currentValue, setCurrentValue] = useState(undefined);

  const fetchPatient = useCallback( async (keyword) => {
    try {
      const response = await fetchWithToken(`${backendUrl}/patients?full_name=${encodeURIComponent(keyword)}`, {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setListPatient(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchPatient('');
  }, [fetchPatient]);

  return (
    <div style={{display:'flex', alignItems:'center'}}>
      <Item
        label="Bệnh nhân"
        name="patient_id"
      >
        <Select
          style={{ width: 200 }}
          showSearch
          placeholder="Chọn bệnh nhân"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={listPatient.map(item => ({
            value: item.id,
            label: `${item.full_name} - ${item.phone_number}`,
            })
          )}
          onChange={(value) => {
            console.log(value);
          }}
          value={currentValue}
          disabled={isEditPage===true}
        />
      </Item>
      <Button type="primary" htmlType="submit" style={{ boxShadow:'none', marginLeft: 10, width:'150px'}} icon={<PlusOutlined />}
          onClick={() => setOpenModal(true)}
          disabled={isEditPage===true}
        > 
          <span>Tạo bệnh nhân</span>
      </Button>
      <PatientCreateModal 
        openModal={openModal} 
        onClose={()=>setOpenModal(false)} 
        onCreatePatient={(patient)=>{
          setListPatient([...listPatient, patient]);
          setCurrentValue(patient.id);
        }}
      />
    </div>
  );
};

export default PatientInput;