import React, { useEffect, useState, useCallback } from 'react';
import { Table, Form, Select, Input, Space, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat('de-DE'); // German locale
  return formatter.format(number);
};
const ListPriceInput = ({product_ids, partner_code, changeProductIds, form}) => {
  const [listPrice, setListPrice] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [listPriceOption, setListPriceOption] = useState([]);
  const [ammount, setAmmount] = useState(0);
  const [lastPrice, setLastPrice] = useState(0);
  console.log(product_ids);

  useEffect(() => {
      const newProductIds = sourceData.map(item => item.id);
      changeProductIds(newProductIds);
  }, [sourceData]);

  useEffect(() => {
    const totalAmount = sourceData
      .map(item => parseFloat(item.price_after_tax.replace(/[^0-9.-]+/g, '')))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setAmmount(totalAmount);
    setLastPrice(totalAmount);
    form.setFieldsValue({price_before_discount: totalAmount});
    form.setFieldsValue({total_amount: totalAmount});
  }, [sourceData, form]);

  const handleDiscountChange = (e) => {
    const discountValue = e.target.value ? parseFloat(e.target.value) : 0;
    const price = ammount - (ammount * discountValue / 100);
    setLastPrice(price);
    form.setFieldsValue({total_amount: price});
  };
  
  const columns = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      render: (text, record, index) => index + 1, // Incrementing index starting from 1
    },
    {
      title: 'Mã sản phẩm',
      dataIndex: 'product_code',
      key: 'product_code',
    },
    {
      title: 'Tên sản phẩm',
      dataIndex: 'product_name',
      key: 'product_name',
      width: 200,
    },
    {
      title: 'Ý nghĩa - Diễn giải',
      dataIndex: 'meaning_description',
      key: 'meaning_description',
      width: 400,
    },
    {
      title: 'Giá niêm yết',
      dataIndex: 'price_after_tax',
      key: 'price_after_tax',
      render: (text, record) => (
        <>
        {formatNumber(text)}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <DeleteOutlined style={{ color: 'red' }} onClick={() => {
            setSourceData(sourceData => sourceData.filter(item => item.id !== record.id));
          }} />
        </Space>
      ),
    },
  ];

  const fetchListPrice = useCallback( async (keyword, product_ids) => {
    try {
      console.log('fetching list price', keyword, product_ids, backendUrl);
      const baseUrl = `${backendUrl}/products`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (keyword) params.append('product_name', keyword);
      if (partner_code) params.append('partner_code', partner_code);
      if (product_ids) params.append('ids', product_ids);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setListPrice(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, [partner_code]);


  const fetchInitSource = useCallback( async (product_ids) => {
    try {
      const baseUrl = `${backendUrl}/products`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (product_ids) params.append('ids', product_ids);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setSourceData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }

  }, []);

  useEffect(() => {
    if(product_ids && product_ids.length > 0)
    {
      fetchInitSource(product_ids);
    }
}, [fetchInitSource, product_ids]);

  useEffect(() => {
    if (partner_code){
      fetchListPrice('');
    }
  }, [partner_code, fetchListPrice]);

  useEffect(() => {
  if (listPrice.length > 0) {

    const options = listPrice.filter(item => !sourceData.find(sourceItem => sourceItem.id === item.id)).map(item => ({
      value: item.id,
      label: `${item.product_code} - ${item.product_name}`,
    }));
    setListPriceOption(options);
  }
   
  }, [listPrice, sourceData]);

  
  return (
    <>
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <div >
          <Form.Item 
            label="Sản phẩm"
            name="product"
          >
            <Select
              style={{marginRight: '10px', width: 400}}
              placeholder="Chọn sản phẩm"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={listPriceOption}
              onChange={(value) => {
                const newProduct = listPrice.find(item => item.id === value);
                if (sourceData.find(item => item.id === newProduct.id)) {
                  return;
                }
                setSourceData([newProduct, ...sourceData]);
              }}
              showSearch
              onSearch={(value) => {
                fetchListPrice(value);
                }
              }
            />
          </Form.Item>

        </div>
        <div style={{display:'flex', alignItems:'center'}}>
          <Form.Item
            name="price_before_discount"
          >
            <Input type='hidden' value={100}/>
          </Form.Item>
          <Typography.Text style={{marginRight: 20, fontSize:'20px'}} strong>Giá bán: {formatNumber(ammount)} VND</Typography.Text>
          
          <Form.Item
            label="Giảm giá"
            name="discount_percent"
          >
            <Input
              style={{width: 150, marginRight: 20}}
              type='number'
              placeholder="Discount (%)"
              onChange={handleDiscountChange}
            />
          </Form.Item>
          <Form.Item
            label="Giá cuối"
            name="total_amount"
          >
            <Input
              type='number'
              style={{width: 250, marginRight: 20}}
              value={lastPrice}
              placeholder={formatNumber(lastPrice) + ' VND'}
            />
          </Form.Item>
        </div>
      </div>

      <Table  scroll={{y: 300 }}  style={{overlay: 'auto !important'}} dataSource={sourceData} columns={columns} rowKey={(record) => record.id} />
    </>
  );
};

export default ListPriceInput;