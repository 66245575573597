import React, { useState,useCallback, useEffect } from 'react';
import { Table, Card, Button, Select, Input, Tag, Space } from 'antd';
import './DashboardPage.css';
import { PlusOutlined, ExportOutlined, EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../../constants';
import { fetchWithToken } from '../share/apiUtils';

const { Search } = Input;

const formatNumber = (number) => {
  const formatter = new Intl.NumberFormat('de-DE'); // German locale
  return formatter.format(number);
};

const TableContent = () => {
  const navigate = useNavigate();
  const [sourceData, setSourceData] = useState([]);
  const [status, setStatus] = useState('');
  const [partner, setPartnerCode] = useState('');
  const [textSearch, setTextSearch] = useState('');

  const fetchTests = useCallback( async (status, partner, text) => {
    try {
      const baseUrl = `${backendUrl}/get_orders`;
      const url = new URL(baseUrl);
      const params = new URLSearchParams();
      if (status) params.append('status', status);
      if (partner) params.append('partner', partner);
      if (text) params.append('text', text);
      url.search = params.toString();
      const response = await fetchWithToken(url.toString(), {
        method: 'GET',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
      }
  
      const data = await response.json();
      setSourceData(data);
    } catch (error) {
      console.error('Error fetching options:', error);
      return [];
    }
  }, []);

  useEffect(() => {
    fetchTests();
  }, [fetchTests]);

  useEffect(() => {
    fetchTests(status, partner, textSearch);
  }, [textSearch, status, partner, fetchTests]);

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1, // Incrementing index starting from 1
      key: 'number',
    },
    {
      title: 'Mã Đơn hàng',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let curType = 'default';
        if (status === 'COMPLETED' || status === 'REFUND') {
          curType = 'success';
        } else if (status === 'WAITING_FOR_PAYMENT' || status === 'DELAY') {
          curType = 'error';
        } else if (status === 'PROCESSING') {
          curType = 'warning';
        }
  
        return <Tag color={curType}> {status.toUpperCase()}</Tag>;
      }
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner_code',
      key: 'partner_code',
      render: (partner_code) => {
        if (partner_code === 'Taman') {
          return 'Tâm An';
        } else {
          return 'Client';
      }},
    },
    {
      title: 'Bác sĩ chỉ định',
      render: (text, record) => record.doctor_id.full_name,
      key: 'name_doctor',
      width: 200,
    },
    {
      title: 'Tên bệnh nhân',
      render: (text, record) => record.patient_id.full_name,
      key: 'name_patient',
      width: 200,
    },
    {
      title: 'Thành tiền (VNĐ)',
      render: (text, record) => formatNumber(record.total_amount) + ' VND',
      key: 'final_price',
    },
    {
      title: 'Điểm lấy mẫu',
      dataIndex: 'sampling_facility',
      key: 'sampling_address',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined style={{ color: 'green' }} onClick={() => {
            navigate('/edit-test?id=' + record.id);
          }} />
        </Space>
      ),
    },

  ];
  
  
  return (
    <>
      <Card bordered={false} style={{padding:'30px'}} >
        <div style={{display:'flex', justifyContent:'space-between'}}>
          <div style={{marginBottom: 20}}>
            <Button type="primary" htmlType="submit" style={{ boxShadow:'none', marginRight: 10}} icon={<ExportOutlined />}> 
              <span>Xuất mẫu</span>
            </Button>
            <Button type="primary" htmlType="submit" style={{ boxShadow:'none'}} icon={<PlusOutlined />} 
              onClick={()=>navigate('/create-test')}
            > 
              <span>Tạo mẫu</span>
            </Button>
          </div>
          <div style={{marginBottom: 20}}>
            <Select
              style={{margin: '0 5px'}}
              showSearch
              placeholder="Chọn trạng thái"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'NEW', label: 'Mới' },
                { value: 'WAITING_FOR_PAYMENT', label: 'Chờ thanh toán' },
                { value: 'PROCESSING', label: 'Đang xử lý' },
                { value: 'REFUND', label: 'Hoàn trả' },
                { value: 'DELAY', label: 'Delay' },
                { value: 'COMPLETED', label: 'Đã hoàn thành' },
                { value: 'CANCELLED', label: 'Huỷ' },
              ]}
              onChange={(value) => {
                setStatus(value);
              }}
            />
            <Select
              showSearch
              style={{margin: '0 5px'}}
              placeholder="Đối tác "
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                { value: 'Taman', label: 'Tâm An' },
                { value: 'Client', label: 'Khách hàng cá nhân' },
              ]}
              onChange={(value) => {
                setPartnerCode(value);
              }}
            />
            <Search 
              style={{margin: '0 10px', width: 250}} 
              placeholder="input search text"
              onChange={(e) => setTextSearch(e.target.value)}
            />
          </div>
        </div>

        <Table dataSource={sourceData} columns={columns} />
      </Card>
    </>
  );
};

export default TableContent;