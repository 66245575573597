// GoogleLoginButton.js

import { React } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { backendUrl } from '../../constants';
import { message } from 'antd';

const GoogleLoginButton = () => {
  const navigate = useNavigate();
  const handleSuccess = (response) => {

    const result = fetch(`${backendUrl}/google-login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: response.credential }),
    });
    result
    .then((res) => {
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      return res.json();
    })
    .then((data) => {
      console.log('data', data)
      localStorage.setItem('token', data.token);
      navigate('/dashboard');
    })
    .catch((error) => {
      message.error('An error occurred during login. Please try again.', 5);
    });
  };

  const handleError = (error) => {
    console.error('Login Error:', error);
  };


 
  return (
    <div  style={{ display: 'flex', justifyContent: 'center', width:'100%' }}>
      <GoogleLogin
        style={{ width: '100%', padding: 0}}
        onSuccess={handleSuccess}
        onError={handleError}
        buttonText="Sign in with Google"
        useOneTap={false}
        width={'350px'}
      />
    </div>
  );
};

export default GoogleLoginButton;
